.user-notification {
  &__description {
    white-space: pre-line;
  }

  &__title {
    font-weight: 600;
  }

  &__url {
    a svg {
      min-width: 16px;
      max-width: 16px;
    }
  }
}
