.fieldset {
  position: relative;
  border: 1px solid $border-color;
  padding: 1.1rem 0.7rem 0.7rem;

  &-title {
    position: absolute;
    top: 0;
    line-height: 1;
    font-size: 1rem;
    font-weight: 600;
    margin: -0.5rem 0 0;
    background: $white;
    padding: 0 0.3rem;
  }
}
