.viber-channel {
  &__table {
    &__device-os,
    &__device-type {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    &__cell {
      &__device-os,
      &__device-type {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      &__actions {
        text-align: right;
      }
    }
  }
}
