.mobile-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: $white;
  border-top: 1px solid #dfdfdf;

  &--logged-out {
    justify-content: space-evenly;
  }

  .nav {
    display: contents;

    .nav-link {
      color: $gray-550;
      width: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 14px;
    }

    .active {
      color: $primary;
    }
  }
}
