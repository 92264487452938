.badge {
  &-notification-channel {
    @extend .badge;
    color: $dark !important;
    background-color: $light-blue;
    > span {
      vertical-align: middle;
    }
  }
}
