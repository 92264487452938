.btn {
  &-cta {
    display: inline-block;
    padding: 10px 45px;
    border-radius: 29px;
    font-size: 1rem;
    transition: 0.3s;
    font-weight: 500;

    &-primary {
      background-color: #3b5998;
      border: 1px solid #20396c;
      color: white;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: white;
        color: #20396c;
        border: 1px solid #20396c;
        transition: 0.3s;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
