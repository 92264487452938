.user-notifications {
  &__table {
    white-space: initial;

    a {
      color: $primary;
      font-weight: 500;
    }

    &__read {
      background-color: rgba($black, 0.0275);
      font-weight: 600;

      a {
        font-weight: 700;
      }
    }

    &__subject {
      width: 40%;
    }

    &__topic {
      width: 25%;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__date {
      width: 25%;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__actions {
      width: 10%;
    }

    &__cell {
      &__actions {
        text-align: right;

        button {
          padding: 0;
        }
      }

      &__topic {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &__date {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }
}
