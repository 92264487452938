.previewPlatform {
  $this: &;
  min-height: 400px;

  &--android {
    background-image: url('../../../assets/images/notification-preview/android.svg');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    #{$this}__contentWrapper {
      display: grid;
      grid-template: 'icon head logo' / 24px auto 48px;
      gap: 12px 16px;
      font-family: 'Open Sans', Roboto, Arial, Helvetica, sans-serif;
      padding: 20px 16px;
      border-radius: 28px;
      background-color: rgb(255, 255, 255);
      box-shadow: rgb(24 39 75 / 12%) 0px 6px 12px -6px,
        rgb(24 39 75 / 8%) 0px 8px 24px -4px;
      width: 90%;
      align-self: flex-end;
      margin-bottom: 30px;

      #{$this}__browser {
        grid-area: icon / icon / icon / icon;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;

        img {
          width: 16px;
          height: 16px;
        }
      }

      #{$this}__text {
        grid-area: head / head / head / head;
        display: grid;
        gap: 4px 8px;
        width: 100%;
        font-size: 12px;
        grid-template:
          'title'
          'message';

        #{$this}__title {
          grid-area: title / title / title / title;
          color: rgb(27, 27, 27);
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          letter-spacing: 0.1px;
          margin: 0px;
          white-space: pre-wrap;
          word-break: break-word;
        }

        #{$this}__description {
          grid-area: message / message / message / message;
          color: rgb(69, 69, 69);
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          letter-spacing: 0.1px;
          margin: 0px;
          width: 100%;
          white-space: pre-wrap;
          word-break: break-word;
        }
      }

      #{$this}__imageWrapper {
        grid-area: logo / logo / logo / logo;
        width: 48px;
        height: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        span {
          height: 48px !important;
          top: 0 !important;
          bottom: 0 !important;
          margin: auto !important;
          border-radius: 50%;
        }

        img {
          border-radius: 50%;
        }
      }
    }
  }

  &--macos {
    background-image: url('../../../assets/images/notification-preview/macos.svg');
    background-size: cover;
    background-position: top right;
    padding-top: 30px;

    @include media-breakpoint-up(lg) {
      padding-top: 50px;
    }

    #{$this}__contentWrapper {
      display: flex;
      flex-direction: row;
      padding: 8px;
      justify-content: space-around;
      font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial,
        sans-serif, 'Apple Color Emoji';
      border-radius: 12px;
      max-width: 100%;
      max-height: 100%;
      background-color: rgb(245, 245, 245);
      box-shadow: rgb(0 0 0 / 14%) 0px 3px 13px 1px,
        rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
      user-select: none;
      width: 256px;
      line-height: 11px;
      font-size: 9px;
      letter-spacing: 0.1px;
      margin: 0 auto;
      gap: 8px;

      @include media-breakpoint-up(lg) {
        transform: scale3d(1.2, 1.2, 1.2);
      }

      #{$this}__browser {
        width: 50px;
        height: 50px;
        border-radius: 8px;
        object-fit: contain;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgb(0 0 0 / 12%) 0px 1px 5px 0px;

        img {
          padding: 4px !important;
        }
      }

      #{$this}__text {
        width: 150px;

        h4,
        p {
          color: $black;
          padding: 0;
          margin: 0;
          font-size: 11px;
        }

        #{$this}__title {
          font-weight: 600;
        }

        #{$this}__description {
          -webkit-line-clamp: 3;
          line-clamp: 3;
        }
      }

      #{$this}__imageWrapper {
        width: 30px;
        height: 30px;
        padding: 0;

        img {
          border-radius: 4px;
        }
      }
    }
  }

  &--windows {
    background-image: url('../../../assets/images/notification-preview/windows.svg');
    background-size: cover;
    background-position: bottom right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 500px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 60px;
    }

    & > *:nth-child(2) {
      border-radius: 0 0 8px 8px !important;
    }

    #{$this}__coverImageWrapper {
      position: relative;
      padding-top: 100%;
      width: 256px;
      display: flex;

      @include media-breakpoint-up(lg) {
        width: 362px;
      }

      span {
        width: 100% !important;
        border-radius: 8px 8px 0 0;

        @include media-breakpoint-down(md) {
          max-height: 150px;
        }
      }
    }

    #{$this}__contentWrapper {
      width: 256px;
      background: rgb(238, 238, 238);
      border: 1px solid rgb(171, 171, 171);
      border-radius: 8px;
      right: 30px;
      bottom: 64px;
      box-shadow: rgb(0 0 0 / 6%) 0px 2px 3px 0px,
        rgb(0 0 0 / 8%) 0px 0.5px 1.5px 0px, rgb(0 0 0 / 8%) 0px 5px 10px 0px;
      padding: 16px;
      height: 110px;
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      gap: 10px 0;

      @include media-breakpoint-up(lg) {
        width: 362px;
      }

      #{$this}__browser {
        height: 20px;
        object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 100%;

        &::after {
          content: 'Google Chrome';
          width: 100%;
          margin-left: 6px;
          font-size: 12px;
          color: $black;
        }

        img {
          width: 20px !important;
        }
      }

      #{$this}__text {
        width: 150px;
        flex: 1;

        h4,
        p {
          color: $black;
          padding: 0;
          margin: 0;
          font-size: 11px;
        }

        #{$this}__title {
          color: rgb(91, 91, 91);
          letter-spacing: 0px;
          font-size: 14px;
          font-weight: 400;
          overflow: hidden;
          width: 100%;
          white-space: pre-wrap;
          word-break: break-word;
        }

        #{$this}__description {
          -webkit-line-clamp: 2;
          line-clamp: 2;
          font-size: 12px;
          color: #a7a7a7;
        }
      }

      #{$this}__imageWrapper {
        width: 40px;
        height: 40px;
        padding: 0;
        background-color: $white;
        margin-right: 16px;
      }
    }
  }

  &--viber {
    display: flex;
    gap: 5px;
    min-height: 50px;

    #{$this}__contentWrapper {
      background-color: #efefef;
      border-radius: 0 12px 12px 12px;
      width: 100%;

      #{$this}__sender {
        color: #816efb;
        font-weight: 600;
        font-size: 15px;
        padding: 10px 0 0 10px;
      }

      #{$this}__imageWrapper {
        padding: unset;

        span {
          width: 100% !important;
        }
      }

      #{$this}__content {
        padding: 0 10px 10px 10px;

        h4,
        p,
        a {
          font-size: 17px;
          margin-bottom: 0;
        }

        #{$this}__title {
          font-weight: 600;
          color: $black;
          display: block;
        }

        #{$this}__description {
          display: block;
          font-weight: normal;
          white-space: break-spaces;
          color: $black;
        }

        #{$this}__link {
          display: block;
          margin-top: 25px;
        }
      }
    }
  }

  &--email {
    display: flex;
    gap: 5px;
    min-height: 50px;
    background-color: #f6f6f6;

    #{$this}__content {
      background-color: $white;
      margin: 10px auto;
      width: 100%;
      max-width: 80%;
      padding: 15px;
      display: flex;
      flex-direction: column;

      #{$this}__title {
        display: block;
        font-weight: 600;
        color: $black;
        text-align: center;
      }

      #{$this}__description {
        display: block;
        font-weight: normal;
        white-space: break-spaces;
        color: $black;
      }

      #{$this}__link {
        border: solid 1px #3498db;
        border-radius: 5px;
        box-sizing: border-box;
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        padding: 12px 25px;
        text-decoration: none;
        text-transform: capitalize;
        background-color: #3498db;
        border-color: #3498db;
        color: #ffffff;
        align-self: center;
      }
    }
  }

  &__imageWrapper {
    position: relative;
    padding-top: 100%;
  }

  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
}
