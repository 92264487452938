.header {
  background-color: #3b5998;

  &-logo {
    color: $primary;
    font-size: 2rem;
    font-weight: 700;
    line-height: 0;

    &:hover {
      color: $primary;
      opacity: 0.7;
      text-decoration: none;
      transition: 0.3s;
    }
  }
}
