@mixin alert-outline-variant($type) {
  .alert-outline {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
      0px 3px 8px -1px rgba(50, 50, 71, 0.05);

    @include alert-variant(
      $color: $gray-600,
      $background: $white,
      $border: $gray-300
    );

    &.alert-#{$type} {
      background-image: linear-gradient(
        map-get($theme-colors, $type),
        map-get($theme-colors, $type)
      );
      background-size: 50% 3px;
      background-position: bottom left;
      background-repeat: no-repeat;
    }
  }
}

@each $type, $color in $theme-colors {
  @include alert-outline-variant($type);
}
