.profile {
  &__channel {
    cursor: pointer;
    padding-right: 2rem;
    padding-left: 2rem;

    &:hover {
      background-color: rgba($black, 0.05);
    }
  }

  &__channel_flag {
    position: absolute;
    top: 2px;
    right: 4px;
  }
}
