header {
  &__logo {
    cursor: pointer;
  }

  margin-bottom: 1rem;
}

.btn-google-auth {
  span {
    vertical-align: middle;
  }
}

.btn {
  font-family: $font-family-sans-serif;

  &,
  &:hover {
    color: $white;
  }

  &-light,
  &-light:hover {
    color: $gray-700;
  }

  &-link,
  &-link:hover {
    color: $primary;
  }

  &-link.disabled:hover {
    color: $gray-600;
  }

  &-outline-primary {
    color: $primary;
  }

  &-outline-secondary {
    color: $secondary;
  }

  &-outline-success {
    color: $success;
  }

  &-outline-info {
    color: $info;
  }

  &-outline-warning {
    color: $warning;
  }

  &-outline-danger {
    color: $danger;
  }

  &-outline-dark {
    color: $dark;
  }

  &-outline-light {
    color: $light;
  }
}

.badge {
  color: $white;

  &.bg-light {
    color: $gray-700;
  }
}
