.admin {
  &-notifications__table {
    a {
      font-weight: 500;
    }

    &__title {
      width: 75%;
    }

    &__date {
      width: 25%;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__cell {
      &__date {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  &-notification {
    &__description {
      white-space: pre-line;
    }

    &__image {
      position: relative;
      padding-top: 100%;
    }

    &-statistics {
      &__description {
        white-space: pre-line;
      }
    }
  }

  /**
   * Admin specific compontents and their overrides
   */
  .card {
    border: 1px solid $gray-300;
  }

  .expandable-content {
    white-space: pre-line;
  }

  .form-label-required {
    color: $red;
    padding: 0 0.2rem;
  }

  .responsive-image-fit {
    position: relative;
    padding-top: 100%;
  }
}
