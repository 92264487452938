// Plain card with title without background color
$spacing-between-card-header-and-title: 0.5rem !default;

.card-plain {
  border: 1px solid #dfdfdf;

  > .card-header {
    padding-bottom: $spacing-between-card-header-and-title;
  }

  > .card-body {
    padding-top: $spacing-between-card-header-and-title;
  }

  &--topics {
    border-radius: 8px;

    @include media-breakpoint-up(lg) {
      &:hover {
        border: 1px solid $primary;
        transform: translateY(-5px);
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 0.2s;
      }
    }
  }
}
