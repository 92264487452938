.onboarding {
  &-header {
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
    background-color: #3b5998;

    @include media-breakpoint-down(md) {
      &__logo {
        margin-bottom: 0.5rem;
      }
    }

    &__navbar {
      .breadcrumb {
        margin-bottom: 0;
        font-weight: 600;
        color: #efd26b;

        a {
          color: $white;
        }

        .active {
          color: $white;
          font-weight: 700;
        }
      }

      .breadcrumb-item + .breadcrumb-item::before {
        float: none;
        vertical-align: text-top;
      }
    }
  }

  &-notification-channels {
    h1,
    h4 {
      font-weight: 500;
    }

    &__label {
      padding-left: 0.3rem;
    }

    .list-group {
      margin-top: 1rem;

      .list-group-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        color: $primary;

        span {
          margin-right: 0.3rem;
        }

        svg {
          position: absolute;
          right: 1rem;
        }
      }
    }
  }
}
